import React from "react";

import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Container from "@material-ui/core/Container";

import "../assets/stylesheets/videos.css";

const DrivePage = () => {
    return (
        <Layout pageName="drive">
            <Helmet>
                <title>The Drive</title>
            </Helmet>
            <Container maxWidth="md">
                {/* eslint-disable jsx-a11y/media-has-caption */}
                <video controls autoPlay width="100%" height="auto">
                    <source
                        src={"https://vgb-vid.s3.amazonaws.com/valle.webm"}
                        type="video/webm"
                    />
                    <source
                        src={"https://vgb-vid.s3.amazonaws.com/valle.mp4"}
                        type="video/mp4"
                    />

                    {"Sorry, your browser doesn't support embedded videos."}
                </video>
            </Container>
        </Layout>
    );
};

export default DrivePage;
